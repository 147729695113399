import { random, times, flatten, sample } from 'lodash'
import { BASE_DELAY, THEME_VALUES } from './constants'

const radius = 15
const whiteSpace = 32

const getX = (pos, w) => {
  return (pos + 1) * w
}

const getY = (pos, h) => {
  return (pos + 1) * h
}

const getContainerSize = (rows, cols) => {
  return {
    width: rows * (radius * 2) + whiteSpace,
    height: cols * (radius * 2) + whiteSpace,
  }
}

export const createRect = (radius, fill, x, y, opacity) => {
  const startScale = random(0, 0.5)
  return {
    shape: 'rect',
    radius,
    fill,
    x,
    y,
    left: 0,
    top: 0,
    opacity: { 0: opacity },
    scale: { [startScale]: 1 },
    isShowStart: true,
    delay: BASE_DELAY + random(300, 2000),
    duration: random(100, 1200),
    initialOpacity: opacity,
  }
}

export const createGrid = (rows, cols, color) => {
  const grid = []
  times(rows, (r) => {
    const t = []
    times(cols, (c) => {
      t.push(
        createRect(
          radius,
          color,
          getX(c, radius + radius),
          getY(r, radius + radius),
          getRandomOpacity(0.7, 0.9)
        )
      )
    })
    grid.push(t)
  })

  return {
    grid: flatten(grid),
    container: getContainerSize(cols, rows),
  }
}

export const getRandomOpacity = (min, max) => {
  return random(min, max)
}

export const getRandomShape = () => {
  return sample(['circle', 'rect', 'polygon'])
}

export const getRandomPoints = () => {
  return sample([3, 4, 5, 6])
}

export const getThemeValues = (themeValue) => {
  const theme = THEME_VALUES[themeValue]
  if (!theme) {
    console.warn(`No theme found in constants file for theme: ${themeValue}`)
    return THEME_VALUES.THEME_DARK
  }

  return theme
}
