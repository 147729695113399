import { useRef, useEffect, useState } from 'preact/hooks'
import mojs from '@mojs/core'
import { random, sampleSize } from 'lodash'
import { linkTypes } from '../content/links'
import { createGrid, getThemeValues } from '../utils'
import { useTheme } from '../context'

export default function MainLinks(props) {
  const [animDone, setAnimDone] = useState(false)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [shapes, setShapes] = useState([])
  const [grid, setGrid] = useState([])
  const [hoverAnims, setHoverAnims] = useState([])
  const { links } = props
  const animDom = useRef()
  const theme = useTheme()
  const themeValues = getThemeValues(theme)

  useEffect(() => {
    if (animDone) {
      return
    }

    const shapes = []
    const { grid, container } = createGrid(5, 5, themeValues.gridColor)
    setContainerSize(container)
    setGrid(grid)

    grid.forEach((rect) => {
      const shape = new mojs.Shape({
        parent: animDom,
        ...rect,
      })
      shape._hide = () => {}
      shapes.push(shape)
    })

    setShapes(shapes)

    const timeline = new mojs.Timeline({
      onPlaybackComplete() {
        setAnimDone(true)
      },
    }).add(shapes)

    timeline.play()
  }, [animDone, themeValues])

  const mouseEnter = () => {
    if (animDone) {
      const hoverAnims = [...sampleSize(shapes, random(5, shapes.length))]

      hoverAnims.forEach((anim, i) => {
        anim.reset()
        anim.tune({
          scale: { 1: random(1, 1.5) },
          delay: 0,
          isShowStart: true,
          opacity: grid[i].initialOpacity,
        })
      })
      setHoverAnims(hoverAnims)
      const timeline = new mojs.Timeline().add(hoverAnims)
      timeline.play()
    }
  }

  const mouseLeave = () => {
    hoverAnims.forEach((anim) => anim.playBackward())
  }

  return (
    <div>
      <div
        className='main-link-container'
        style={containerSize}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <div ref={animDom} className='anim-container' />
      </div>
      <div className='links-container'>
        <nav>
          <ul>
            {links.map((link, i) => (
              <li key={i} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                <a
                  target={link.type === linkTypes.HASH ? '_self' : '_blank'}
                  rel='noopener noreferrer'
                  href={link.url}
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}
