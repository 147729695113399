import { createContext } from 'preact'
import { useContext } from 'preact/hooks'

const Theme = createContext()

export const ThemeProvider = (props) => {
  return <Theme.Provider value={props.value}>{props.children}</Theme.Provider>
}

export const useTheme = () => {
  const theme = useContext(Theme)
  return theme
}
