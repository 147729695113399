export const linkTypes = {
  NORMAL: 'normal',
  HASH: 'hash',
}

export const galleryLinks = {
  header: 'Gallery',
  links: [
    {
      title: 'Bees',
      url: 'https://www.jannehuotari.fi/gallery/bees/',
      type: linkTypes.NORMAL,
    },
    {
      title: 'Hay',
      url: 'https://www.jannehuotari.fi/gallery/hay/',
      type: linkTypes.NORMAL,
    },
    {
      title: 'Space Trouble',
      url: 'https://js13kgames.com/entries/space-trouble',
      type: linkTypes.NORMAL,
    },
    {
      title: 'UI Experiments',
      url: 'https://jannehuo.github.io/react-ui-stuff/#/',
      type: linkTypes.NORMAL,
    },
  ],
}

export const mainLinks = [
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/in/janne-huotari/',
    type: linkTypes.NORMAL,
  },
  {
    title: 'GitHub',
    url: 'https://github.com/jannehuo',
    type: linkTypes.NORMAL,
  },
  {
    title: 'Gallery',
    url: '#gallery',
    type: linkTypes.HASH,
  },
]
