export const colors = {
  BACKGROUND: '#f0eadd',
  WHITE: '#e3e3e3',
  BLACK: '#111113',
  RED: '#631922',
  ORANGE: '#9c3e00',
  PURPLE: '#330066',
  CYAN: 'cyan',
  MAGENTA: 'magenta',
}

export const BASE_DELAY = 500
export const THEME_DARK = 'THEME_DARK'
export const THEME_LIGHT = 'THEME_LIGHT'
export const THEMES = [THEME_DARK, THEME_LIGHT]

export const THEME_VALUES = {
  [THEME_DARK]: {
    bgColor: colors.BLACK,
    gridColor: colors.WHITE,
    shapeColors: [colors.MAGENTA, colors.CYAN, colors.WHITE],
    textColor: colors.WHITE,
  },
  [THEME_LIGHT]: {
    bgColor: colors.WHITE,
    gridColor: colors.BLACK,
    shapeColors: [colors.MAGENTA, colors.CYAN, colors.BLACK],
    textColor: colors.BLACK,
  },
}
