import './style'
import Gallery from './components/gallery'
import MainLinks from './components/mainLinks'
import { galleryLinks, mainLinks } from './content/links'
import { ThemeProvider } from './context'
import { THEMES } from './constants'
import { sample } from 'lodash'
import { getThemeValues } from './utils'

export default function App() {
  const theme = sample(THEMES)
  const themeValues = getThemeValues(theme)

  const style = {
    backgroundColor: themeValues.bgColor,
    color: themeValues.textColor,
  }

  return (
    <ThemeProvider value={theme}>
      <div style={style}>
        <div className='full-page-container full-page-container--center'>
          <MainLinks links={mainLinks} />
        </div>
        <div className='gallery-container' id='gallery'>
          <Gallery links={galleryLinks} />
        </div>
      </div>
    </ThemeProvider>
  )
}
