import { useRef, useEffect } from 'preact/hooks'
import mojs from '@mojs/core'
import { times } from 'lodash'
import { getRandomShape, getRandomPoints, getThemeValues } from '../utils'
import { useTheme } from '../context'

function GalleryAnim({ url, title, theme }) {
  // const [animDone, setAnimDone] = useState(false)
  const themeValues = getThemeValues(theme)
  const animDom = useRef()
  this.mainTriangle = useRef()
  const shapeColors = themeValues.shapeColors
  const positionOffsetValues = [-5, 5, 0]
  const rotations = [5, -5, 0]
  const shapes = []
  useEffect(() => {
    const shape = getRandomShape()
    let shapeOptions = {
      parent: animDom,
      shape,
      radius: 50,
      isShowStart: true,
      fill: 'transparent',
      strokeWidth: 1,
    }

    if (shape === 'polygon') {
      shapeOptions = {
        ...shapeOptions,
        points: getRandomPoints(),
      }
    }

    times(3, (i) => {
      shapes.push(
        new mojs.Shape({
          ...shapeOptions,
          stroke: shapeColors[i],
          x: { 0: positionOffsetValues[i] },
          y: { 0: positionOffsetValues[i] },
          rotate: { 0: rotations[i] },
          duration: 200,
        })
      )
    })
  })

  const mouseEnter = () => {
    shapes.forEach((shape) => shape.play())
  }

  const mouseLeave = () => {
    shapes.forEach((shape) => shape.replayBackward())
  }

  return (
    <a href={url} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div className='gallery-anim-container' ref={animDom} />
      <h2 className='gallery-element-header'>{title}</h2>
    </a>
  )
}

export default function Gallery(props) {
  const { links } = props
  const theme = useTheme()

  return links.links.map((link, i) => {
    return (
      <div key={i} className='gallery-element'>
        <div className='galler-element-content'>
          <GalleryAnim url={link.url} title={link.title} theme={theme} />
        </div>
      </div>
    )
  })
}
